import dayjs, {Dayjs} from "dayjs"
import MemberStatusEnum from "../enums/MemberStatusEnum";
import MemberTypeEnum from "../enums/MemberTypeEnum";

export default class MinimalMember {
    public firstName: string
    public lastName: string
    public gender?: 'M' | 'F'
    public type: MemberTypeEnum
    public avatar: string | null
    public matricule: string
    public community: string
    public status: MemberStatusEnum
    public statusLocked: boolean
    public probationEndDate?: string
    public communityEmail?: string
    public communityTelephone?: string
    public createdAt: Dayjs

    constructor(data: any) {
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.gender = ['MALE', 'M'].includes(data.gender) ? 'M'
            : ['FEMALE', 'F'].includes(data.gender) ? 'F' : undefined

        this.type = MemberTypeEnum.tryParse(data.type)
        this.avatar = data.avatar || data.photo || null
        this.matricule = data.matricule
        this.probationEndDate = data.probationEndDate
        this.status = MemberStatusEnum.tryParse(data.status)
        this.community = data.community
        this.statusLocked = data.statusLocked
        this.communityEmail = data.communityEmail || ""
        this.communityTelephone = data.communityTelephoneNumber || ""
        this.createdAt = dayjs(data.createdAt)
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`
    }

    static getClone = (data: any) => {
        return new MinimalMember({
            ...data,
            reference: data.id,
        })
    }

    getStatusMap = () => {
        switch (this.status) {
            case MemberStatusEnum.probation:
                return { color: 'warning' }
            case MemberStatusEnum.active:
                return { color: 'success' }
            case MemberStatusEnum.inactive:
            case MemberStatusEnum.struck:
                return { color: 'secondary' }
            case MemberStatusEnum.probationFreezed:
                return { color: 'info' }
            case MemberStatusEnum.dead:
            default:
                return { color: 'secondary' }
        }
    }
}
